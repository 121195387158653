<template>
  <LayoutNew>
    <MDBLoading v-model="loadingSpinner" class="analytics-loader" loadingText="" icon="spinner-grow" />
    <h1 class="text-center">Upload Analytics</h1>
    <!-- upload file -->
    <MDBCard id="UploadFile" class="p-2 mb-2">
      <MDBCardHeader>
        <h4 class="mb-0">Upload File</h4>
        <p class="mb-0"><small>Only excel files are accepted.</small></p>
      </MDBCardHeader>
      <MDBCardBody>
        <form>
          <MDBSelect v-model:options="monthsOption" v-model:selected="form.month" label="Month" class="mb-3" />
          <MDBSelect v-model:options="yearsOption" v-model:selected="form.year" label="Year" class="my-3" />
          <MDBFile @change="handleFileUpload($event)" class="my-3" />
        </form>
      </MDBCardBody>
      <MDBCardFooter class="py-3 d-flex justify-content-end">
        <MDBBtn type="submit" @click="submit" color="primary" size="sm">
          Upload
        </MDBBtn>
      </MDBCardFooter>
    </MDBCard>
    <!-- download report -->
    <MDBCard id="DownloadReport" class="p-2 mb-2">
      <MDBCardHeader>
        <h4 class="mb-0">Download Report</h4>
        <p class="mb-0">
          <small>Download Monthly Report Per Asset Label File.</small>
        </p>
      </MDBCardHeader>
      <MDBCardBody>
        <form>
          <MDBSelect v-model:options="monthsOption" v-model:selected="formDownload.downloadMonth" label="Month"
            class="mb-3" />
          <MDBSelect v-model:options="yearsOption" v-model:selected="formDownload.downloadYear" label="Year"
            class="my-3" />
        </form>
      </MDBCardBody>
      <MDBCardFooter class="py-3 d-flex justify-content-end">
        <MDBBtn type="submit" @click="download" color="primary" size="sm">
          Download
        </MDBBtn>
      </MDBCardFooter>
    </MDBCard>
    <!-- toast -->
    <MDBToast v-model="toastObject.state" :delay="2000" autohide position="top-right" appendToBody stacking
      width="350px" :color="toastObject.color" text="white" :icon="toastObject.icon">
      <template #title>
        {{ toastObject.message }}
      </template>
    </MDBToast>
  </LayoutNew>
</template>

<script setup>
import {
  MDBBtn,
  MDBToast,
  MDBFile,
  MDBSelect,
  MDBLoading,
  MDBCard,
  MDBCardHeader,
  MDBCardFooter,
  MDBCardBody,
} from "mdb-vue-ui-kit";
import LayoutNew from "./LayoutNew.vue";
import { reactive, ref } from "vue";
import axios from "axios";
import { useTitle } from "@vueuse/core";

useTitle("Upload Analytics | CreatorShield");

const toastObject = ref({
  state: false,
  message: "",
  color: "",
  icon: "",
});

const form = reactive({
  month: "",
  year: "",
  file: [],
});

const formDownload = reactive({
  downloadMonth: "",
  downloadYear: "",
});

const handleFileUpload = (event) => {
  form.file = event.target.files[0];
};

const monthsOption = [
  { text: "January", value: 1 },
  { text: "February", value: 2 },
  { text: "March", value: 3 },
  { text: "April", value: 4 },
  { text: "May", value: 5 },
  { text: "June", value: 6 },
  { text: "July", value: 7 },
  { text: "August", value: 8 },
  { text: "September", value: 9 },
  { text: "October", value: 10 },
  { text: "November", value: 11 },
  { text: "December", value: 12 },
];

const yearsOption = [];
const createYearsOption = () => {
  for (let year = 2010; year <= 2100; year++) {
    yearsOption.push({
      text: year,
      value: year,
    });
  }
  return yearsOption;
};

createYearsOption();
let loadingSpinner = ref(false);

const submit = () => {
  let formData = new FormData();
  formData.append("analytics", form.file);
  formData.append("month", form.month);
  formData.append("year", form.year);
  loadingSpinner.value = true;
  axios
    .post("api/analytics/UploadAnalytics", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(function (response) {
      if (response.status == 200) {
        toastObject.value.state = true;
        toastObject.value.message = "Successfully submitted!";
        toastObject.value.color = "success";
        toastObject.value.icon = "fas fa-check fa-lg me-2";
        loadingSpinner.value = false;
      }
    })
    .catch(function () {
      toastObject.value.state = true;
      toastObject.value.message = "An error occurred during request.";
      toastObject.value.color = "danger";
      toastObject.value.icon = "fas fa-exclamation-circle fa-lg me-2";
      loadingSpinner.value = false;
    });
};

const download = () => {
  loadingSpinner.value = true;
  axios
    .get(
      "api/revenues/ExportToExcel?Month=" +
      formDownload.downloadMonth +
      "&Year=" +
      formDownload.downloadYear,
      {
        responseType: "blob",
      }
    )
    .then(function (response) {
      if (response.status == 200) {
        let fileURL = window.URL.createObjectURL(
          new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        let fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute(
          "download",
          "CreatorShield-AssetLabelMonthlyReport - " +
          formDownload.downloadMonth +
          " " +
          formDownload.downloadYear +
          ".xlsx"
        );
        document.body.appendChild(fileLink);

        fileLink.click();

        toastObject.value.state = true;
        toastObject.value.message = "Successfully submitted!";
        toastObject.value.color = "success";
        toastObject.value.icon = "fas fa-check fa-lg me-2";
        loadingSpinner.value = false;
      }
    })
    .catch(function () {
      toastObject.value.state = true;
      toastObject.value.message = "An error occurred during request.";
      toastObject.value.color = "danger";
      toastObject.value.icon = "fas fa-exclamation-circle fa-lg me-2";
      loadingSpinner.value = false;
    });
};
</script>

<style scoped>
.btn {
  background-color: var(--primary);
}
</style>

<style>
.analytics-loader {
  color: var(--accent);
}
</style>
